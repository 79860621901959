import { default as index2O7PFgP8Y8Meta } from "/home/circleci/project/src/pages/achievement/[id]/index.vue?macro=true";
import { default as _91id_93woYcVxA5O8Meta } from "/home/circleci/project/src/pages/cloudstore/asset/[id].vue?macro=true";
import { default as indextoYLaVyuxJMeta } from "/home/circleci/project/src/pages/cloudstore/index.vue?macro=true";
import { default as avatarWNZd6XKhvlMeta } from "/home/circleci/project/src/pages/cloudstore/list/avatar.vue?macro=true";
import { default as avatar_partx5rper9xgEMeta } from "/home/circleci/project/src/pages/cloudstore/list/avatar_part.vue?macro=true";
import { default as housingwydgBM0b97Meta } from "/home/circleci/project/src/pages/cloudstore/list/housing.vue?macro=true";
import { default as complete9qSNSbY5E6Meta } from "/home/circleci/project/src/pages/contact/complete.vue?macro=true";
import { default as confirmation300GV09KEmMeta } from "/home/circleci/project/src/pages/contact/confirmation.vue?macro=true";
import { default as index3Gt14nwyygMeta } from "/home/circleci/project/src/pages/contact/index.vue?macro=true";
import { default as guideW4luTKZrvxMeta } from "/home/circleci/project/src/pages/guide.vue?macro=true";
import { default as helpqDVZmnlxsHMeta } from "/home/circleci/project/src/pages/help.vue?macro=true";
import { default as indexPMCJkF9I5xMeta } from "/home/circleci/project/src/pages/index.vue?macro=true";
import { default as indexTJ8gEvReu5Meta } from "/home/circleci/project/src/pages/mypage/achievement/index.vue?macro=true";
import { default as _91id_934mAX8k6IIhMeta } from "/home/circleci/project/src/pages/mypage/assets/[id].vue?macro=true";
import { default as avataroPMIXDTJeKMeta } from "/home/circleci/project/src/pages/mypage/assets/avatar.vue?macro=true";
import { default as avatar_partuQDZE35QhqMeta } from "/home/circleci/project/src/pages/mypage/assets/avatar_part.vue?macro=true";
import { default as _91id_93yIm9MWwiRlMeta } from "/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/[id].vue?macro=true";
import { default as uploaderSJuCHHADX6Meta } from "/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/uploader.vue?macro=true";
import { default as furniturerMBsran8KtMeta } from "/home/circleci/project/src/pages/mypage/assets/furniture.vue?macro=true";
import { default as uploadererg7sbxvZGMeta } from "/home/circleci/project/src/pages/mypage/assets/uploader.vue?macro=true";
import { default as _91id_931mWGjCgBpbMeta } from "/home/circleci/project/src/pages/mypage/assets/vketbox/[id].vue?macro=true";
import { default as indexqhmex0ZerMMeta } from "/home/circleci/project/src/pages/mypage/assets/vketbox/index.vue?macro=true";
import { default as uploader1UCrPbFl38Meta } from "/home/circleci/project/src/pages/mypage/assets/vketbox/uploader.vue?macro=true";
import { default as indexUPP8rImaD0Meta } from "/home/circleci/project/src/pages/mypage/block/index.vue?macro=true";
import { default as _91id_93yuhjBZ3j1xMeta } from "/home/circleci/project/src/pages/mypage/event/edit/[id].vue?macro=true";
import { default as newhQQaE90XQiMeta } from "/home/circleci/project/src/pages/mypage/event/new.vue?macro=true";
import { default as organisedMJOmh0Eu6AMeta } from "/home/circleci/project/src/pages/mypage/event/organised.vue?macro=true";
import { default as participating0EPnKWZsQcMeta } from "/home/circleci/project/src/pages/mypage/event/participating.vue?macro=true";
import { default as followqx9sCk3dGkMeta } from "/home/circleci/project/src/pages/mypage/follow.vue?macro=true";
import { default as followerxmlsMMDtIXMeta } from "/home/circleci/project/src/pages/mypage/follower.vue?macro=true";
import { default as indexHlrWpFNZSVMeta } from "/home/circleci/project/src/pages/mypage/index.vue?macro=true";
import { default as eventrQ1DM7E7b3Meta } from "/home/circleci/project/src/pages/mypage/like/event.vue?macro=true";
import { default as storePbeR8MGPjiMeta } from "/home/circleci/project/src/pages/mypage/like/store.vue?macro=true";
import { default as worldfIXejs4o4WMeta } from "/home/circleci/project/src/pages/mypage/like/world.vue?macro=true";
import { default as messagehwWhbV51ziMeta } from "/home/circleci/project/src/pages/mypage/message.vue?macro=true";
import { default as indexxxa4n0UPFMMeta } from "/home/circleci/project/src/pages/mypage/myroom/index.vue?macro=true";
import { default as footprints7X08IyShg1Meta } from "/home/circleci/project/src/pages/mypage/notification/footprints.vue?macro=true";
import { default as giftmpBIxBSdu1Meta } from "/home/circleci/project/src/pages/mypage/notification/gift.vue?macro=true";
import { default as historyvR1Gn0dSD9Meta } from "/home/circleci/project/src/pages/mypage/notification/history.vue?macro=true";
import { default as indexmMBzzTVKToMeta } from "/home/circleci/project/src/pages/mypage/notification/index.vue?macro=true";
import { default as indextQ5FmsgUYKMeta } from "/home/circleci/project/src/pages/mypage/orderhistory/[id]/index.vue?macro=true";
import { default as indexNuCOgbn41pMeta } from "/home/circleci/project/src/pages/mypage/orderhistory/index.vue?macro=true";
import { default as profilemyK7tujKNGMeta } from "/home/circleci/project/src/pages/mypage/profile.vue?macro=true";
import { default as indexahA77uMctsMeta } from "/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]-[dd]/index.vue?macro=true";
import { default as index7EPyE86SeOMeta } from "/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]/index.vue?macro=true";
import { default as indexQD2lKpyKLZMeta } from "/home/circleci/project/src/pages/mypage/sales/list/[yyyy]/index.vue?macro=true";
import { default as indexKqkYXbtyrHMeta } from "/home/circleci/project/src/pages/mypage/sales/list/index.vue?macro=true";
import { default as indexkzzWkpqx7fMeta } from "/home/circleci/project/src/pages/mypage/sales/transfer-setting/index.vue?macro=true";
import { default as indexBZCaNeIydGMeta } from "/home/circleci/project/src/pages/mypage/sales/transfer-status/index.vue?macro=true";
import { default as settinggCe6j2Eh3DMeta } from "/home/circleci/project/src/pages/mypage/setting.vue?macro=true";
import { default as listE88o3kpwZ3Meta } from "/home/circleci/project/src/pages/myroom/list.vue?macro=true";
import { default as index4TNeElGSFPMeta } from "/home/circleci/project/src/pages/payment/index.vue?macro=true";
import { default as index3iJheNbbruMeta } from "/home/circleci/project/src/pages/play/event/[id]/index.vue?macro=true";
import { default as index70pg6OSH3tMeta } from "/home/circleci/project/src/pages/play/event/index.vue?macro=true";
import { default as listBFs0GIvc9bMeta } from "/home/circleci/project/src/pages/play/event/list.vue?macro=true";
import { default as indexBZlUg0h7bsMeta } from "/home/circleci/project/src/pages/play/world/[id]/index.vue?macro=true";
import { default as indexhFugl59NeuMeta } from "/home/circleci/project/src/pages/play/world/index.vue?macro=true";
import { default as listN7Tg21b0EFMeta } from "/home/circleci/project/src/pages/play/world/list.vue?macro=true";
import { default as achievementqhjIHN0EBEMeta } from "/home/circleci/project/src/pages/profile/[id]/achievement.vue?macro=true";
import { default as cloudstoreg7j6KqJxB0Meta } from "/home/circleci/project/src/pages/profile/[id]/cloudstore.vue?macro=true";
import { default as indexKyjUJeLOnOMeta } from "/home/circleci/project/src/pages/profile/[id]/index.vue?macro=true";
import { default as worldyU4nEuTG7VMeta } from "/home/circleci/project/src/pages/profile/[id]/world.vue?macro=true";
import { default as indexK4kQxQ8rpTMeta } from "/home/circleci/project/src/pages/room/[id]/index.vue?macro=true";
import { default as indexynF36WTUfDMeta } from "/home/circleci/project/src/pages/talkroom/index.vue?macro=true";
import { default as list99EMwI8BaOMeta } from "/home/circleci/project/src/pages/talkroom/list.vue?macro=true";
import { default as indexxXBK72epEZMeta } from "/home/circleci/project/src/pages/tutorial/index.vue?macro=true";
import { default as index1Miyd6uTTnMeta } from "/home/circleci/project/src/pages/vketboothmaker-marche/index.vue?macro=true";
export default [
  {
    name: index2O7PFgP8Y8Meta?.name ?? "achievement-id___ja___default",
    path: index2O7PFgP8Y8Meta?.path ?? "/achievement/:id()",
    meta: index2O7PFgP8Y8Meta || {},
    alias: index2O7PFgP8Y8Meta?.alias || [],
    redirect: index2O7PFgP8Y8Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/achievement/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index2O7PFgP8Y8Meta?.name ?? "achievement-id___ja",
    path: index2O7PFgP8Y8Meta?.path ?? "/ja/achievement/:id()",
    meta: index2O7PFgP8Y8Meta || {},
    alias: index2O7PFgP8Y8Meta?.alias || [],
    redirect: index2O7PFgP8Y8Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/achievement/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index2O7PFgP8Y8Meta?.name ?? "achievement-id___en",
    path: index2O7PFgP8Y8Meta?.path ?? "/en/achievement/:id()",
    meta: index2O7PFgP8Y8Meta || {},
    alias: index2O7PFgP8Y8Meta?.alias || [],
    redirect: index2O7PFgP8Y8Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/achievement/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93woYcVxA5O8Meta?.name ?? "cloudstore-asset-id___ja___default",
    path: _91id_93woYcVxA5O8Meta?.path ?? "/cloudstore/asset/:id()",
    meta: _91id_93woYcVxA5O8Meta || {},
    alias: _91id_93woYcVxA5O8Meta?.alias || [],
    redirect: _91id_93woYcVxA5O8Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/asset/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93woYcVxA5O8Meta?.name ?? "cloudstore-asset-id___ja",
    path: _91id_93woYcVxA5O8Meta?.path ?? "/ja/cloudstore/asset/:id()",
    meta: _91id_93woYcVxA5O8Meta || {},
    alias: _91id_93woYcVxA5O8Meta?.alias || [],
    redirect: _91id_93woYcVxA5O8Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/asset/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93woYcVxA5O8Meta?.name ?? "cloudstore-asset-id___en",
    path: _91id_93woYcVxA5O8Meta?.path ?? "/en/cloudstore/asset/:id()",
    meta: _91id_93woYcVxA5O8Meta || {},
    alias: _91id_93woYcVxA5O8Meta?.alias || [],
    redirect: _91id_93woYcVxA5O8Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/asset/[id].vue").then(m => m.default || m)
  },
  {
    name: indextoYLaVyuxJMeta?.name ?? "cloudstore___ja___default",
    path: indextoYLaVyuxJMeta?.path ?? "/cloudstore",
    meta: indextoYLaVyuxJMeta || {},
    alias: indextoYLaVyuxJMeta?.alias || [],
    redirect: indextoYLaVyuxJMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/index.vue").then(m => m.default || m)
  },
  {
    name: indextoYLaVyuxJMeta?.name ?? "cloudstore___ja",
    path: indextoYLaVyuxJMeta?.path ?? "/ja/cloudstore",
    meta: indextoYLaVyuxJMeta || {},
    alias: indextoYLaVyuxJMeta?.alias || [],
    redirect: indextoYLaVyuxJMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/index.vue").then(m => m.default || m)
  },
  {
    name: indextoYLaVyuxJMeta?.name ?? "cloudstore___en",
    path: indextoYLaVyuxJMeta?.path ?? "/en/cloudstore",
    meta: indextoYLaVyuxJMeta || {},
    alias: indextoYLaVyuxJMeta?.alias || [],
    redirect: indextoYLaVyuxJMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/index.vue").then(m => m.default || m)
  },
  {
    name: avatarWNZd6XKhvlMeta?.name ?? "cloudstore-list-avatar___ja___default",
    path: avatarWNZd6XKhvlMeta?.path ?? "/cloudstore/list/avatar",
    meta: avatarWNZd6XKhvlMeta || {},
    alias: avatarWNZd6XKhvlMeta?.alias || [],
    redirect: avatarWNZd6XKhvlMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/avatar.vue").then(m => m.default || m)
  },
  {
    name: avatarWNZd6XKhvlMeta?.name ?? "cloudstore-list-avatar___ja",
    path: avatarWNZd6XKhvlMeta?.path ?? "/ja/cloudstore/list/avatar",
    meta: avatarWNZd6XKhvlMeta || {},
    alias: avatarWNZd6XKhvlMeta?.alias || [],
    redirect: avatarWNZd6XKhvlMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/avatar.vue").then(m => m.default || m)
  },
  {
    name: avatarWNZd6XKhvlMeta?.name ?? "cloudstore-list-avatar___en",
    path: avatarWNZd6XKhvlMeta?.path ?? "/en/cloudstore/list/avatar",
    meta: avatarWNZd6XKhvlMeta || {},
    alias: avatarWNZd6XKhvlMeta?.alias || [],
    redirect: avatarWNZd6XKhvlMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/avatar.vue").then(m => m.default || m)
  },
  {
    name: avatar_partx5rper9xgEMeta?.name ?? "cloudstore-list-avatar_part___ja___default",
    path: avatar_partx5rper9xgEMeta?.path ?? "/cloudstore/list/avatar_part",
    meta: avatar_partx5rper9xgEMeta || {},
    alias: avatar_partx5rper9xgEMeta?.alias || [],
    redirect: avatar_partx5rper9xgEMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/avatar_part.vue").then(m => m.default || m)
  },
  {
    name: avatar_partx5rper9xgEMeta?.name ?? "cloudstore-list-avatar_part___ja",
    path: avatar_partx5rper9xgEMeta?.path ?? "/ja/cloudstore/list/avatar_part",
    meta: avatar_partx5rper9xgEMeta || {},
    alias: avatar_partx5rper9xgEMeta?.alias || [],
    redirect: avatar_partx5rper9xgEMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/avatar_part.vue").then(m => m.default || m)
  },
  {
    name: avatar_partx5rper9xgEMeta?.name ?? "cloudstore-list-avatar_part___en",
    path: avatar_partx5rper9xgEMeta?.path ?? "/en/cloudstore/list/avatar_part",
    meta: avatar_partx5rper9xgEMeta || {},
    alias: avatar_partx5rper9xgEMeta?.alias || [],
    redirect: avatar_partx5rper9xgEMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/avatar_part.vue").then(m => m.default || m)
  },
  {
    name: housingwydgBM0b97Meta?.name ?? "cloudstore-list-housing___ja___default",
    path: housingwydgBM0b97Meta?.path ?? "/cloudstore/list/housing",
    meta: housingwydgBM0b97Meta || {},
    alias: housingwydgBM0b97Meta?.alias || [],
    redirect: housingwydgBM0b97Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/housing.vue").then(m => m.default || m)
  },
  {
    name: housingwydgBM0b97Meta?.name ?? "cloudstore-list-housing___ja",
    path: housingwydgBM0b97Meta?.path ?? "/ja/cloudstore/list/housing",
    meta: housingwydgBM0b97Meta || {},
    alias: housingwydgBM0b97Meta?.alias || [],
    redirect: housingwydgBM0b97Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/housing.vue").then(m => m.default || m)
  },
  {
    name: housingwydgBM0b97Meta?.name ?? "cloudstore-list-housing___en",
    path: housingwydgBM0b97Meta?.path ?? "/en/cloudstore/list/housing",
    meta: housingwydgBM0b97Meta || {},
    alias: housingwydgBM0b97Meta?.alias || [],
    redirect: housingwydgBM0b97Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/cloudstore/list/housing.vue").then(m => m.default || m)
  },
  {
    name: complete9qSNSbY5E6Meta?.name ?? "contact-complete___ja___default",
    path: complete9qSNSbY5E6Meta?.path ?? "/contact/complete",
    meta: complete9qSNSbY5E6Meta || {},
    alias: complete9qSNSbY5E6Meta?.alias || [],
    redirect: complete9qSNSbY5E6Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/complete.vue").then(m => m.default || m)
  },
  {
    name: complete9qSNSbY5E6Meta?.name ?? "contact-complete___ja",
    path: complete9qSNSbY5E6Meta?.path ?? "/ja/contact/complete",
    meta: complete9qSNSbY5E6Meta || {},
    alias: complete9qSNSbY5E6Meta?.alias || [],
    redirect: complete9qSNSbY5E6Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/complete.vue").then(m => m.default || m)
  },
  {
    name: complete9qSNSbY5E6Meta?.name ?? "contact-complete___en",
    path: complete9qSNSbY5E6Meta?.path ?? "/en/contact/complete",
    meta: complete9qSNSbY5E6Meta || {},
    alias: complete9qSNSbY5E6Meta?.alias || [],
    redirect: complete9qSNSbY5E6Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/complete.vue").then(m => m.default || m)
  },
  {
    name: confirmation300GV09KEmMeta?.name ?? "contact-confirmation___ja___default",
    path: confirmation300GV09KEmMeta?.path ?? "/contact/confirmation",
    meta: confirmation300GV09KEmMeta || {},
    alias: confirmation300GV09KEmMeta?.alias || [],
    redirect: confirmation300GV09KEmMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmation300GV09KEmMeta?.name ?? "contact-confirmation___ja",
    path: confirmation300GV09KEmMeta?.path ?? "/ja/contact/confirmation",
    meta: confirmation300GV09KEmMeta || {},
    alias: confirmation300GV09KEmMeta?.alias || [],
    redirect: confirmation300GV09KEmMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/confirmation.vue").then(m => m.default || m)
  },
  {
    name: confirmation300GV09KEmMeta?.name ?? "contact-confirmation___en",
    path: confirmation300GV09KEmMeta?.path ?? "/en/contact/confirmation",
    meta: confirmation300GV09KEmMeta || {},
    alias: confirmation300GV09KEmMeta?.alias || [],
    redirect: confirmation300GV09KEmMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/confirmation.vue").then(m => m.default || m)
  },
  {
    name: index3Gt14nwyygMeta?.name ?? "contact___ja___default",
    path: index3Gt14nwyygMeta?.path ?? "/contact",
    meta: index3Gt14nwyygMeta || {},
    alias: index3Gt14nwyygMeta?.alias || [],
    redirect: index3Gt14nwyygMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: index3Gt14nwyygMeta?.name ?? "contact___ja",
    path: index3Gt14nwyygMeta?.path ?? "/ja/contact",
    meta: index3Gt14nwyygMeta || {},
    alias: index3Gt14nwyygMeta?.alias || [],
    redirect: index3Gt14nwyygMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: index3Gt14nwyygMeta?.name ?? "contact___en",
    path: index3Gt14nwyygMeta?.path ?? "/en/contact",
    meta: index3Gt14nwyygMeta || {},
    alias: index3Gt14nwyygMeta?.alias || [],
    redirect: index3Gt14nwyygMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: guideW4luTKZrvxMeta?.name ?? "guide___ja___default",
    path: guideW4luTKZrvxMeta?.path ?? "/guide",
    meta: guideW4luTKZrvxMeta || {},
    alias: guideW4luTKZrvxMeta?.alias || [],
    redirect: guideW4luTKZrvxMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: guideW4luTKZrvxMeta?.name ?? "guide___ja",
    path: guideW4luTKZrvxMeta?.path ?? "/ja/guide",
    meta: guideW4luTKZrvxMeta || {},
    alias: guideW4luTKZrvxMeta?.alias || [],
    redirect: guideW4luTKZrvxMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: guideW4luTKZrvxMeta?.name ?? "guide___en",
    path: guideW4luTKZrvxMeta?.path ?? "/en/guide",
    meta: guideW4luTKZrvxMeta || {},
    alias: guideW4luTKZrvxMeta?.alias || [],
    redirect: guideW4luTKZrvxMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: helpqDVZmnlxsHMeta?.name ?? "help___ja___default",
    path: helpqDVZmnlxsHMeta?.path ?? "/help",
    meta: helpqDVZmnlxsHMeta || {},
    alias: helpqDVZmnlxsHMeta?.alias || [],
    redirect: helpqDVZmnlxsHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/help.vue").then(m => m.default || m)
  },
  {
    name: helpqDVZmnlxsHMeta?.name ?? "help___ja",
    path: helpqDVZmnlxsHMeta?.path ?? "/ja/help",
    meta: helpqDVZmnlxsHMeta || {},
    alias: helpqDVZmnlxsHMeta?.alias || [],
    redirect: helpqDVZmnlxsHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/help.vue").then(m => m.default || m)
  },
  {
    name: helpqDVZmnlxsHMeta?.name ?? "help___en",
    path: helpqDVZmnlxsHMeta?.path ?? "/en/help",
    meta: helpqDVZmnlxsHMeta || {},
    alias: helpqDVZmnlxsHMeta?.alias || [],
    redirect: helpqDVZmnlxsHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexPMCJkF9I5xMeta?.name ?? "index___ja___default",
    path: indexPMCJkF9I5xMeta?.path ?? "/",
    meta: indexPMCJkF9I5xMeta || {},
    alias: indexPMCJkF9I5xMeta?.alias || [],
    redirect: indexPMCJkF9I5xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMCJkF9I5xMeta?.name ?? "index___ja",
    path: indexPMCJkF9I5xMeta?.path ?? "/ja",
    meta: indexPMCJkF9I5xMeta || {},
    alias: indexPMCJkF9I5xMeta?.alias || [],
    redirect: indexPMCJkF9I5xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMCJkF9I5xMeta?.name ?? "index___en",
    path: indexPMCJkF9I5xMeta?.path ?? "/en",
    meta: indexPMCJkF9I5xMeta || {},
    alias: indexPMCJkF9I5xMeta?.alias || [],
    redirect: indexPMCJkF9I5xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTJ8gEvReu5Meta?.name ?? "mypage-achievement___ja___default",
    path: indexTJ8gEvReu5Meta?.path ?? "/mypage/achievement",
    meta: indexTJ8gEvReu5Meta || {},
    alias: indexTJ8gEvReu5Meta?.alias || [],
    redirect: indexTJ8gEvReu5Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/achievement/index.vue").then(m => m.default || m)
  },
  {
    name: indexTJ8gEvReu5Meta?.name ?? "mypage-achievement___ja",
    path: indexTJ8gEvReu5Meta?.path ?? "/ja/mypage/achievement",
    meta: indexTJ8gEvReu5Meta || {},
    alias: indexTJ8gEvReu5Meta?.alias || [],
    redirect: indexTJ8gEvReu5Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/achievement/index.vue").then(m => m.default || m)
  },
  {
    name: indexTJ8gEvReu5Meta?.name ?? "mypage-achievement___en",
    path: indexTJ8gEvReu5Meta?.path ?? "/en/mypage/achievement",
    meta: indexTJ8gEvReu5Meta || {},
    alias: indexTJ8gEvReu5Meta?.alias || [],
    redirect: indexTJ8gEvReu5Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/achievement/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934mAX8k6IIhMeta?.name ?? "mypage-assets-id___ja___default",
    path: _91id_934mAX8k6IIhMeta?.path ?? "/mypage/assets/:id()",
    meta: _91id_934mAX8k6IIhMeta || {},
    alias: _91id_934mAX8k6IIhMeta?.alias || [],
    redirect: _91id_934mAX8k6IIhMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934mAX8k6IIhMeta?.name ?? "mypage-assets-id___ja",
    path: _91id_934mAX8k6IIhMeta?.path ?? "/ja/mypage/assets/:id()",
    meta: _91id_934mAX8k6IIhMeta || {},
    alias: _91id_934mAX8k6IIhMeta?.alias || [],
    redirect: _91id_934mAX8k6IIhMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934mAX8k6IIhMeta?.name ?? "mypage-assets-id___en",
    path: _91id_934mAX8k6IIhMeta?.path ?? "/en/mypage/assets/:id()",
    meta: _91id_934mAX8k6IIhMeta || {},
    alias: _91id_934mAX8k6IIhMeta?.alias || [],
    redirect: _91id_934mAX8k6IIhMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/[id].vue").then(m => m.default || m)
  },
  {
    name: avataroPMIXDTJeKMeta?.name ?? "mypage-assets-avatar___ja___default",
    path: avataroPMIXDTJeKMeta?.path ?? "/mypage/assets/avatar",
    meta: avataroPMIXDTJeKMeta || {},
    alias: avataroPMIXDTJeKMeta?.alias || [],
    redirect: avataroPMIXDTJeKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/avatar.vue").then(m => m.default || m)
  },
  {
    name: avataroPMIXDTJeKMeta?.name ?? "mypage-assets-avatar___ja",
    path: avataroPMIXDTJeKMeta?.path ?? "/ja/mypage/assets/avatar",
    meta: avataroPMIXDTJeKMeta || {},
    alias: avataroPMIXDTJeKMeta?.alias || [],
    redirect: avataroPMIXDTJeKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/avatar.vue").then(m => m.default || m)
  },
  {
    name: avataroPMIXDTJeKMeta?.name ?? "mypage-assets-avatar___en",
    path: avataroPMIXDTJeKMeta?.path ?? "/en/mypage/assets/avatar",
    meta: avataroPMIXDTJeKMeta || {},
    alias: avataroPMIXDTJeKMeta?.alias || [],
    redirect: avataroPMIXDTJeKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/avatar.vue").then(m => m.default || m)
  },
  {
    name: avatar_partuQDZE35QhqMeta?.name ?? "mypage-assets-avatar_part___ja___default",
    path: avatar_partuQDZE35QhqMeta?.path ?? "/mypage/assets/avatar_part",
    meta: avatar_partuQDZE35QhqMeta || {},
    alias: avatar_partuQDZE35QhqMeta?.alias || [],
    redirect: avatar_partuQDZE35QhqMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/avatar_part.vue").then(m => m.default || m)
  },
  {
    name: avatar_partuQDZE35QhqMeta?.name ?? "mypage-assets-avatar_part___ja",
    path: avatar_partuQDZE35QhqMeta?.path ?? "/ja/mypage/assets/avatar_part",
    meta: avatar_partuQDZE35QhqMeta || {},
    alias: avatar_partuQDZE35QhqMeta?.alias || [],
    redirect: avatar_partuQDZE35QhqMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/avatar_part.vue").then(m => m.default || m)
  },
  {
    name: avatar_partuQDZE35QhqMeta?.name ?? "mypage-assets-avatar_part___en",
    path: avatar_partuQDZE35QhqMeta?.path ?? "/en/mypage/assets/avatar_part",
    meta: avatar_partuQDZE35QhqMeta || {},
    alias: avatar_partuQDZE35QhqMeta?.alias || [],
    redirect: avatar_partuQDZE35QhqMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/avatar_part.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yIm9MWwiRlMeta?.name ?? "mypage-assets-bf5dnCPq3u-id___ja___default",
    path: _91id_93yIm9MWwiRlMeta?.path ?? "/mypage/assets/bf5dnCPq3u/:id()",
    meta: _91id_93yIm9MWwiRlMeta || {},
    alias: _91id_93yIm9MWwiRlMeta?.alias || [],
    redirect: _91id_93yIm9MWwiRlMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yIm9MWwiRlMeta?.name ?? "mypage-assets-bf5dnCPq3u-id___ja",
    path: _91id_93yIm9MWwiRlMeta?.path ?? "/ja/mypage/assets/bf5dnCPq3u/:id()",
    meta: _91id_93yIm9MWwiRlMeta || {},
    alias: _91id_93yIm9MWwiRlMeta?.alias || [],
    redirect: _91id_93yIm9MWwiRlMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yIm9MWwiRlMeta?.name ?? "mypage-assets-bf5dnCPq3u-id___en",
    path: _91id_93yIm9MWwiRlMeta?.path ?? "/en/mypage/assets/bf5dnCPq3u/:id()",
    meta: _91id_93yIm9MWwiRlMeta || {},
    alias: _91id_93yIm9MWwiRlMeta?.alias || [],
    redirect: _91id_93yIm9MWwiRlMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/[id].vue").then(m => m.default || m)
  },
  {
    name: uploaderSJuCHHADX6Meta?.name ?? "mypage-assets-bf5dnCPq3u-uploader___ja___default",
    path: uploaderSJuCHHADX6Meta?.path ?? "/mypage/assets/bf5dnCPq3u/uploader",
    meta: uploaderSJuCHHADX6Meta || {},
    alias: uploaderSJuCHHADX6Meta?.alias || [],
    redirect: uploaderSJuCHHADX6Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/uploader.vue").then(m => m.default || m)
  },
  {
    name: uploaderSJuCHHADX6Meta?.name ?? "mypage-assets-bf5dnCPq3u-uploader___ja",
    path: uploaderSJuCHHADX6Meta?.path ?? "/ja/mypage/assets/bf5dnCPq3u/uploader",
    meta: uploaderSJuCHHADX6Meta || {},
    alias: uploaderSJuCHHADX6Meta?.alias || [],
    redirect: uploaderSJuCHHADX6Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/uploader.vue").then(m => m.default || m)
  },
  {
    name: uploaderSJuCHHADX6Meta?.name ?? "mypage-assets-bf5dnCPq3u-uploader___en",
    path: uploaderSJuCHHADX6Meta?.path ?? "/en/mypage/assets/bf5dnCPq3u/uploader",
    meta: uploaderSJuCHHADX6Meta || {},
    alias: uploaderSJuCHHADX6Meta?.alias || [],
    redirect: uploaderSJuCHHADX6Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/bf5dnCPq3u/uploader.vue").then(m => m.default || m)
  },
  {
    name: furniturerMBsran8KtMeta?.name ?? "mypage-assets-furniture___ja___default",
    path: furniturerMBsran8KtMeta?.path ?? "/mypage/assets/furniture",
    meta: furniturerMBsran8KtMeta || {},
    alias: furniturerMBsran8KtMeta?.alias || [],
    redirect: furniturerMBsran8KtMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/furniture.vue").then(m => m.default || m)
  },
  {
    name: furniturerMBsran8KtMeta?.name ?? "mypage-assets-furniture___ja",
    path: furniturerMBsran8KtMeta?.path ?? "/ja/mypage/assets/furniture",
    meta: furniturerMBsran8KtMeta || {},
    alias: furniturerMBsran8KtMeta?.alias || [],
    redirect: furniturerMBsran8KtMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/furniture.vue").then(m => m.default || m)
  },
  {
    name: furniturerMBsran8KtMeta?.name ?? "mypage-assets-furniture___en",
    path: furniturerMBsran8KtMeta?.path ?? "/en/mypage/assets/furniture",
    meta: furniturerMBsran8KtMeta || {},
    alias: furniturerMBsran8KtMeta?.alias || [],
    redirect: furniturerMBsran8KtMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/furniture.vue").then(m => m.default || m)
  },
  {
    name: uploadererg7sbxvZGMeta?.name ?? "mypage-assets-uploader___ja___default",
    path: uploadererg7sbxvZGMeta?.path ?? "/mypage/assets/uploader",
    meta: uploadererg7sbxvZGMeta || {},
    alias: uploadererg7sbxvZGMeta?.alias || [],
    redirect: uploadererg7sbxvZGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/uploader.vue").then(m => m.default || m)
  },
  {
    name: uploadererg7sbxvZGMeta?.name ?? "mypage-assets-uploader___ja",
    path: uploadererg7sbxvZGMeta?.path ?? "/ja/mypage/assets/uploader",
    meta: uploadererg7sbxvZGMeta || {},
    alias: uploadererg7sbxvZGMeta?.alias || [],
    redirect: uploadererg7sbxvZGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/uploader.vue").then(m => m.default || m)
  },
  {
    name: uploadererg7sbxvZGMeta?.name ?? "mypage-assets-uploader___en",
    path: uploadererg7sbxvZGMeta?.path ?? "/en/mypage/assets/uploader",
    meta: uploadererg7sbxvZGMeta || {},
    alias: uploadererg7sbxvZGMeta?.alias || [],
    redirect: uploadererg7sbxvZGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/uploader.vue").then(m => m.default || m)
  },
  {
    name: _91id_931mWGjCgBpbMeta?.name ?? "mypage-assets-vketbox-id___ja___default",
    path: _91id_931mWGjCgBpbMeta?.path ?? "/mypage/assets/vketbox/:id()",
    meta: _91id_931mWGjCgBpbMeta || {},
    alias: _91id_931mWGjCgBpbMeta?.alias || [],
    redirect: _91id_931mWGjCgBpbMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_931mWGjCgBpbMeta?.name ?? "mypage-assets-vketbox-id___ja",
    path: _91id_931mWGjCgBpbMeta?.path ?? "/ja/mypage/assets/vketbox/:id()",
    meta: _91id_931mWGjCgBpbMeta || {},
    alias: _91id_931mWGjCgBpbMeta?.alias || [],
    redirect: _91id_931mWGjCgBpbMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_931mWGjCgBpbMeta?.name ?? "mypage-assets-vketbox-id___en",
    path: _91id_931mWGjCgBpbMeta?.path ?? "/en/mypage/assets/vketbox/:id()",
    meta: _91id_931mWGjCgBpbMeta || {},
    alias: _91id_931mWGjCgBpbMeta?.alias || [],
    redirect: _91id_931mWGjCgBpbMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqhmex0ZerMMeta?.name ?? "mypage-assets-vketbox___ja___default",
    path: indexqhmex0ZerMMeta?.path ?? "/mypage/assets/vketbox",
    meta: indexqhmex0ZerMMeta || {},
    alias: indexqhmex0ZerMMeta?.alias || [],
    redirect: indexqhmex0ZerMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhmex0ZerMMeta?.name ?? "mypage-assets-vketbox___ja",
    path: indexqhmex0ZerMMeta?.path ?? "/ja/mypage/assets/vketbox",
    meta: indexqhmex0ZerMMeta || {},
    alias: indexqhmex0ZerMMeta?.alias || [],
    redirect: indexqhmex0ZerMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhmex0ZerMMeta?.name ?? "mypage-assets-vketbox___en",
    path: indexqhmex0ZerMMeta?.path ?? "/en/mypage/assets/vketbox",
    meta: indexqhmex0ZerMMeta || {},
    alias: indexqhmex0ZerMMeta?.alias || [],
    redirect: indexqhmex0ZerMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/index.vue").then(m => m.default || m)
  },
  {
    name: uploader1UCrPbFl38Meta?.name ?? "mypage-assets-vketbox-uploader___ja___default",
    path: uploader1UCrPbFl38Meta?.path ?? "/mypage/assets/vketbox/uploader",
    meta: uploader1UCrPbFl38Meta || {},
    alias: uploader1UCrPbFl38Meta?.alias || [],
    redirect: uploader1UCrPbFl38Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/uploader.vue").then(m => m.default || m)
  },
  {
    name: uploader1UCrPbFl38Meta?.name ?? "mypage-assets-vketbox-uploader___ja",
    path: uploader1UCrPbFl38Meta?.path ?? "/ja/mypage/assets/vketbox/uploader",
    meta: uploader1UCrPbFl38Meta || {},
    alias: uploader1UCrPbFl38Meta?.alias || [],
    redirect: uploader1UCrPbFl38Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/uploader.vue").then(m => m.default || m)
  },
  {
    name: uploader1UCrPbFl38Meta?.name ?? "mypage-assets-vketbox-uploader___en",
    path: uploader1UCrPbFl38Meta?.path ?? "/en/mypage/assets/vketbox/uploader",
    meta: uploader1UCrPbFl38Meta || {},
    alias: uploader1UCrPbFl38Meta?.alias || [],
    redirect: uploader1UCrPbFl38Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/assets/vketbox/uploader.vue").then(m => m.default || m)
  },
  {
    name: indexUPP8rImaD0Meta?.name ?? "mypage-block___ja___default",
    path: indexUPP8rImaD0Meta?.path ?? "/mypage/block",
    meta: indexUPP8rImaD0Meta || {},
    alias: indexUPP8rImaD0Meta?.alias || [],
    redirect: indexUPP8rImaD0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/block/index.vue").then(m => m.default || m)
  },
  {
    name: indexUPP8rImaD0Meta?.name ?? "mypage-block___ja",
    path: indexUPP8rImaD0Meta?.path ?? "/ja/mypage/block",
    meta: indexUPP8rImaD0Meta || {},
    alias: indexUPP8rImaD0Meta?.alias || [],
    redirect: indexUPP8rImaD0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/block/index.vue").then(m => m.default || m)
  },
  {
    name: indexUPP8rImaD0Meta?.name ?? "mypage-block___en",
    path: indexUPP8rImaD0Meta?.path ?? "/en/mypage/block",
    meta: indexUPP8rImaD0Meta || {},
    alias: indexUPP8rImaD0Meta?.alias || [],
    redirect: indexUPP8rImaD0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/block/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuhjBZ3j1xMeta?.name ?? "mypage-event-edit-id___ja___default",
    path: _91id_93yuhjBZ3j1xMeta?.path ?? "/mypage/event/edit/:id()",
    meta: _91id_93yuhjBZ3j1xMeta || {},
    alias: _91id_93yuhjBZ3j1xMeta?.alias || [],
    redirect: _91id_93yuhjBZ3j1xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuhjBZ3j1xMeta?.name ?? "mypage-event-edit-id___ja",
    path: _91id_93yuhjBZ3j1xMeta?.path ?? "/ja/mypage/event/edit/:id()",
    meta: _91id_93yuhjBZ3j1xMeta || {},
    alias: _91id_93yuhjBZ3j1xMeta?.alias || [],
    redirect: _91id_93yuhjBZ3j1xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuhjBZ3j1xMeta?.name ?? "mypage-event-edit-id___en",
    path: _91id_93yuhjBZ3j1xMeta?.path ?? "/en/mypage/event/edit/:id()",
    meta: _91id_93yuhjBZ3j1xMeta || {},
    alias: _91id_93yuhjBZ3j1xMeta?.alias || [],
    redirect: _91id_93yuhjBZ3j1xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: newhQQaE90XQiMeta?.name ?? "mypage-event-new___ja___default",
    path: newhQQaE90XQiMeta?.path ?? "/mypage/event/new",
    meta: newhQQaE90XQiMeta || {},
    alias: newhQQaE90XQiMeta?.alias || [],
    redirect: newhQQaE90XQiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/new.vue").then(m => m.default || m)
  },
  {
    name: newhQQaE90XQiMeta?.name ?? "mypage-event-new___ja",
    path: newhQQaE90XQiMeta?.path ?? "/ja/mypage/event/new",
    meta: newhQQaE90XQiMeta || {},
    alias: newhQQaE90XQiMeta?.alias || [],
    redirect: newhQQaE90XQiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/new.vue").then(m => m.default || m)
  },
  {
    name: newhQQaE90XQiMeta?.name ?? "mypage-event-new___en",
    path: newhQQaE90XQiMeta?.path ?? "/en/mypage/event/new",
    meta: newhQQaE90XQiMeta || {},
    alias: newhQQaE90XQiMeta?.alias || [],
    redirect: newhQQaE90XQiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/new.vue").then(m => m.default || m)
  },
  {
    name: organisedMJOmh0Eu6AMeta?.name ?? "mypage-event-organised___ja___default",
    path: organisedMJOmh0Eu6AMeta?.path ?? "/mypage/event/organised",
    meta: organisedMJOmh0Eu6AMeta || {},
    alias: organisedMJOmh0Eu6AMeta?.alias || [],
    redirect: organisedMJOmh0Eu6AMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/organised.vue").then(m => m.default || m)
  },
  {
    name: organisedMJOmh0Eu6AMeta?.name ?? "mypage-event-organised___ja",
    path: organisedMJOmh0Eu6AMeta?.path ?? "/ja/mypage/event/organised",
    meta: organisedMJOmh0Eu6AMeta || {},
    alias: organisedMJOmh0Eu6AMeta?.alias || [],
    redirect: organisedMJOmh0Eu6AMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/organised.vue").then(m => m.default || m)
  },
  {
    name: organisedMJOmh0Eu6AMeta?.name ?? "mypage-event-organised___en",
    path: organisedMJOmh0Eu6AMeta?.path ?? "/en/mypage/event/organised",
    meta: organisedMJOmh0Eu6AMeta || {},
    alias: organisedMJOmh0Eu6AMeta?.alias || [],
    redirect: organisedMJOmh0Eu6AMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/organised.vue").then(m => m.default || m)
  },
  {
    name: participating0EPnKWZsQcMeta?.name ?? "mypage-event-participating___ja___default",
    path: participating0EPnKWZsQcMeta?.path ?? "/mypage/event/participating",
    meta: participating0EPnKWZsQcMeta || {},
    alias: participating0EPnKWZsQcMeta?.alias || [],
    redirect: participating0EPnKWZsQcMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/participating.vue").then(m => m.default || m)
  },
  {
    name: participating0EPnKWZsQcMeta?.name ?? "mypage-event-participating___ja",
    path: participating0EPnKWZsQcMeta?.path ?? "/ja/mypage/event/participating",
    meta: participating0EPnKWZsQcMeta || {},
    alias: participating0EPnKWZsQcMeta?.alias || [],
    redirect: participating0EPnKWZsQcMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/participating.vue").then(m => m.default || m)
  },
  {
    name: participating0EPnKWZsQcMeta?.name ?? "mypage-event-participating___en",
    path: participating0EPnKWZsQcMeta?.path ?? "/en/mypage/event/participating",
    meta: participating0EPnKWZsQcMeta || {},
    alias: participating0EPnKWZsQcMeta?.alias || [],
    redirect: participating0EPnKWZsQcMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/event/participating.vue").then(m => m.default || m)
  },
  {
    name: followqx9sCk3dGkMeta?.name ?? "mypage-follow___ja___default",
    path: followqx9sCk3dGkMeta?.path ?? "/mypage/follow",
    meta: followqx9sCk3dGkMeta || {},
    alias: followqx9sCk3dGkMeta?.alias || [],
    redirect: followqx9sCk3dGkMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/follow.vue").then(m => m.default || m)
  },
  {
    name: followqx9sCk3dGkMeta?.name ?? "mypage-follow___ja",
    path: followqx9sCk3dGkMeta?.path ?? "/ja/mypage/follow",
    meta: followqx9sCk3dGkMeta || {},
    alias: followqx9sCk3dGkMeta?.alias || [],
    redirect: followqx9sCk3dGkMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/follow.vue").then(m => m.default || m)
  },
  {
    name: followqx9sCk3dGkMeta?.name ?? "mypage-follow___en",
    path: followqx9sCk3dGkMeta?.path ?? "/en/mypage/follow",
    meta: followqx9sCk3dGkMeta || {},
    alias: followqx9sCk3dGkMeta?.alias || [],
    redirect: followqx9sCk3dGkMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/follow.vue").then(m => m.default || m)
  },
  {
    name: followerxmlsMMDtIXMeta?.name ?? "mypage-follower___ja___default",
    path: followerxmlsMMDtIXMeta?.path ?? "/mypage/follower",
    meta: followerxmlsMMDtIXMeta || {},
    alias: followerxmlsMMDtIXMeta?.alias || [],
    redirect: followerxmlsMMDtIXMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/follower.vue").then(m => m.default || m)
  },
  {
    name: followerxmlsMMDtIXMeta?.name ?? "mypage-follower___ja",
    path: followerxmlsMMDtIXMeta?.path ?? "/ja/mypage/follower",
    meta: followerxmlsMMDtIXMeta || {},
    alias: followerxmlsMMDtIXMeta?.alias || [],
    redirect: followerxmlsMMDtIXMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/follower.vue").then(m => m.default || m)
  },
  {
    name: followerxmlsMMDtIXMeta?.name ?? "mypage-follower___en",
    path: followerxmlsMMDtIXMeta?.path ?? "/en/mypage/follower",
    meta: followerxmlsMMDtIXMeta || {},
    alias: followerxmlsMMDtIXMeta?.alias || [],
    redirect: followerxmlsMMDtIXMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/follower.vue").then(m => m.default || m)
  },
  {
    name: indexHlrWpFNZSVMeta?.name ?? "mypage___ja___default",
    path: indexHlrWpFNZSVMeta?.path ?? "/mypage",
    meta: indexHlrWpFNZSVMeta || {},
    alias: indexHlrWpFNZSVMeta?.alias || [],
    redirect: indexHlrWpFNZSVMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: indexHlrWpFNZSVMeta?.name ?? "mypage___ja",
    path: indexHlrWpFNZSVMeta?.path ?? "/ja/mypage",
    meta: indexHlrWpFNZSVMeta || {},
    alias: indexHlrWpFNZSVMeta?.alias || [],
    redirect: indexHlrWpFNZSVMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: indexHlrWpFNZSVMeta?.name ?? "mypage___en",
    path: indexHlrWpFNZSVMeta?.path ?? "/en/mypage",
    meta: indexHlrWpFNZSVMeta || {},
    alias: indexHlrWpFNZSVMeta?.alias || [],
    redirect: indexHlrWpFNZSVMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: eventrQ1DM7E7b3Meta?.name ?? "mypage-like-event___ja___default",
    path: eventrQ1DM7E7b3Meta?.path ?? "/mypage/like/event",
    meta: eventrQ1DM7E7b3Meta || {},
    alias: eventrQ1DM7E7b3Meta?.alias || [],
    redirect: eventrQ1DM7E7b3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/event.vue").then(m => m.default || m)
  },
  {
    name: eventrQ1DM7E7b3Meta?.name ?? "mypage-like-event___ja",
    path: eventrQ1DM7E7b3Meta?.path ?? "/ja/mypage/like/event",
    meta: eventrQ1DM7E7b3Meta || {},
    alias: eventrQ1DM7E7b3Meta?.alias || [],
    redirect: eventrQ1DM7E7b3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/event.vue").then(m => m.default || m)
  },
  {
    name: eventrQ1DM7E7b3Meta?.name ?? "mypage-like-event___en",
    path: eventrQ1DM7E7b3Meta?.path ?? "/en/mypage/like/event",
    meta: eventrQ1DM7E7b3Meta || {},
    alias: eventrQ1DM7E7b3Meta?.alias || [],
    redirect: eventrQ1DM7E7b3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/event.vue").then(m => m.default || m)
  },
  {
    name: storePbeR8MGPjiMeta?.name ?? "mypage-like-store___ja___default",
    path: storePbeR8MGPjiMeta?.path ?? "/mypage/like/store",
    meta: storePbeR8MGPjiMeta || {},
    alias: storePbeR8MGPjiMeta?.alias || [],
    redirect: storePbeR8MGPjiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/store.vue").then(m => m.default || m)
  },
  {
    name: storePbeR8MGPjiMeta?.name ?? "mypage-like-store___ja",
    path: storePbeR8MGPjiMeta?.path ?? "/ja/mypage/like/store",
    meta: storePbeR8MGPjiMeta || {},
    alias: storePbeR8MGPjiMeta?.alias || [],
    redirect: storePbeR8MGPjiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/store.vue").then(m => m.default || m)
  },
  {
    name: storePbeR8MGPjiMeta?.name ?? "mypage-like-store___en",
    path: storePbeR8MGPjiMeta?.path ?? "/en/mypage/like/store",
    meta: storePbeR8MGPjiMeta || {},
    alias: storePbeR8MGPjiMeta?.alias || [],
    redirect: storePbeR8MGPjiMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/store.vue").then(m => m.default || m)
  },
  {
    name: worldfIXejs4o4WMeta?.name ?? "mypage-like-world___ja___default",
    path: worldfIXejs4o4WMeta?.path ?? "/mypage/like/world",
    meta: worldfIXejs4o4WMeta || {},
    alias: worldfIXejs4o4WMeta?.alias || [],
    redirect: worldfIXejs4o4WMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/world.vue").then(m => m.default || m)
  },
  {
    name: worldfIXejs4o4WMeta?.name ?? "mypage-like-world___ja",
    path: worldfIXejs4o4WMeta?.path ?? "/ja/mypage/like/world",
    meta: worldfIXejs4o4WMeta || {},
    alias: worldfIXejs4o4WMeta?.alias || [],
    redirect: worldfIXejs4o4WMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/world.vue").then(m => m.default || m)
  },
  {
    name: worldfIXejs4o4WMeta?.name ?? "mypage-like-world___en",
    path: worldfIXejs4o4WMeta?.path ?? "/en/mypage/like/world",
    meta: worldfIXejs4o4WMeta || {},
    alias: worldfIXejs4o4WMeta?.alias || [],
    redirect: worldfIXejs4o4WMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/like/world.vue").then(m => m.default || m)
  },
  {
    name: messagehwWhbV51ziMeta?.name ?? "mypage-message___ja___default",
    path: messagehwWhbV51ziMeta?.path ?? "/mypage/message",
    meta: messagehwWhbV51ziMeta || {},
    alias: messagehwWhbV51ziMeta?.alias || [],
    redirect: messagehwWhbV51ziMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/message.vue").then(m => m.default || m)
  },
  {
    name: messagehwWhbV51ziMeta?.name ?? "mypage-message___ja",
    path: messagehwWhbV51ziMeta?.path ?? "/ja/mypage/message",
    meta: messagehwWhbV51ziMeta || {},
    alias: messagehwWhbV51ziMeta?.alias || [],
    redirect: messagehwWhbV51ziMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/message.vue").then(m => m.default || m)
  },
  {
    name: messagehwWhbV51ziMeta?.name ?? "mypage-message___en",
    path: messagehwWhbV51ziMeta?.path ?? "/en/mypage/message",
    meta: messagehwWhbV51ziMeta || {},
    alias: messagehwWhbV51ziMeta?.alias || [],
    redirect: messagehwWhbV51ziMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/message.vue").then(m => m.default || m)
  },
  {
    name: indexxxa4n0UPFMMeta?.name ?? "mypage-myroom___ja___default",
    path: indexxxa4n0UPFMMeta?.path ?? "/mypage/myroom",
    meta: indexxxa4n0UPFMMeta || {},
    alias: indexxxa4n0UPFMMeta?.alias || [],
    redirect: indexxxa4n0UPFMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/myroom/index.vue").then(m => m.default || m)
  },
  {
    name: indexxxa4n0UPFMMeta?.name ?? "mypage-myroom___ja",
    path: indexxxa4n0UPFMMeta?.path ?? "/ja/mypage/myroom",
    meta: indexxxa4n0UPFMMeta || {},
    alias: indexxxa4n0UPFMMeta?.alias || [],
    redirect: indexxxa4n0UPFMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/myroom/index.vue").then(m => m.default || m)
  },
  {
    name: indexxxa4n0UPFMMeta?.name ?? "mypage-myroom___en",
    path: indexxxa4n0UPFMMeta?.path ?? "/en/mypage/myroom",
    meta: indexxxa4n0UPFMMeta || {},
    alias: indexxxa4n0UPFMMeta?.alias || [],
    redirect: indexxxa4n0UPFMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/myroom/index.vue").then(m => m.default || m)
  },
  {
    name: footprints7X08IyShg1Meta?.name ?? "mypage-notification-footprints___ja___default",
    path: footprints7X08IyShg1Meta?.path ?? "/mypage/notification/footprints",
    meta: footprints7X08IyShg1Meta || {},
    alias: footprints7X08IyShg1Meta?.alias || [],
    redirect: footprints7X08IyShg1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/footprints.vue").then(m => m.default || m)
  },
  {
    name: footprints7X08IyShg1Meta?.name ?? "mypage-notification-footprints___ja",
    path: footprints7X08IyShg1Meta?.path ?? "/ja/mypage/notification/footprints",
    meta: footprints7X08IyShg1Meta || {},
    alias: footprints7X08IyShg1Meta?.alias || [],
    redirect: footprints7X08IyShg1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/footprints.vue").then(m => m.default || m)
  },
  {
    name: footprints7X08IyShg1Meta?.name ?? "mypage-notification-footprints___en",
    path: footprints7X08IyShg1Meta?.path ?? "/en/mypage/notification/footprints",
    meta: footprints7X08IyShg1Meta || {},
    alias: footprints7X08IyShg1Meta?.alias || [],
    redirect: footprints7X08IyShg1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/footprints.vue").then(m => m.default || m)
  },
  {
    name: giftmpBIxBSdu1Meta?.name ?? "mypage-notification-gift___ja___default",
    path: giftmpBIxBSdu1Meta?.path ?? "/mypage/notification/gift",
    meta: giftmpBIxBSdu1Meta || {},
    alias: giftmpBIxBSdu1Meta?.alias || [],
    redirect: giftmpBIxBSdu1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmpBIxBSdu1Meta?.name ?? "mypage-notification-gift___ja",
    path: giftmpBIxBSdu1Meta?.path ?? "/ja/mypage/notification/gift",
    meta: giftmpBIxBSdu1Meta || {},
    alias: giftmpBIxBSdu1Meta?.alias || [],
    redirect: giftmpBIxBSdu1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/gift.vue").then(m => m.default || m)
  },
  {
    name: giftmpBIxBSdu1Meta?.name ?? "mypage-notification-gift___en",
    path: giftmpBIxBSdu1Meta?.path ?? "/en/mypage/notification/gift",
    meta: giftmpBIxBSdu1Meta || {},
    alias: giftmpBIxBSdu1Meta?.alias || [],
    redirect: giftmpBIxBSdu1Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/gift.vue").then(m => m.default || m)
  },
  {
    name: historyvR1Gn0dSD9Meta?.name ?? "mypage-notification-history___ja___default",
    path: historyvR1Gn0dSD9Meta?.path ?? "/mypage/notification/history",
    meta: historyvR1Gn0dSD9Meta || {},
    alias: historyvR1Gn0dSD9Meta?.alias || [],
    redirect: historyvR1Gn0dSD9Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/history.vue").then(m => m.default || m)
  },
  {
    name: historyvR1Gn0dSD9Meta?.name ?? "mypage-notification-history___ja",
    path: historyvR1Gn0dSD9Meta?.path ?? "/ja/mypage/notification/history",
    meta: historyvR1Gn0dSD9Meta || {},
    alias: historyvR1Gn0dSD9Meta?.alias || [],
    redirect: historyvR1Gn0dSD9Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/history.vue").then(m => m.default || m)
  },
  {
    name: historyvR1Gn0dSD9Meta?.name ?? "mypage-notification-history___en",
    path: historyvR1Gn0dSD9Meta?.path ?? "/en/mypage/notification/history",
    meta: historyvR1Gn0dSD9Meta || {},
    alias: historyvR1Gn0dSD9Meta?.alias || [],
    redirect: historyvR1Gn0dSD9Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/history.vue").then(m => m.default || m)
  },
  {
    name: indexmMBzzTVKToMeta?.name ?? "mypage-notification___ja___default",
    path: indexmMBzzTVKToMeta?.path ?? "/mypage/notification",
    meta: indexmMBzzTVKToMeta || {},
    alias: indexmMBzzTVKToMeta?.alias || [],
    redirect: indexmMBzzTVKToMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/index.vue").then(m => m.default || m)
  },
  {
    name: indexmMBzzTVKToMeta?.name ?? "mypage-notification___ja",
    path: indexmMBzzTVKToMeta?.path ?? "/ja/mypage/notification",
    meta: indexmMBzzTVKToMeta || {},
    alias: indexmMBzzTVKToMeta?.alias || [],
    redirect: indexmMBzzTVKToMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/index.vue").then(m => m.default || m)
  },
  {
    name: indexmMBzzTVKToMeta?.name ?? "mypage-notification___en",
    path: indexmMBzzTVKToMeta?.path ?? "/en/mypage/notification",
    meta: indexmMBzzTVKToMeta || {},
    alias: indexmMBzzTVKToMeta?.alias || [],
    redirect: indexmMBzzTVKToMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/notification/index.vue").then(m => m.default || m)
  },
  {
    name: indextQ5FmsgUYKMeta?.name ?? "mypage-orderhistory-id___ja___default",
    path: indextQ5FmsgUYKMeta?.path ?? "/mypage/orderhistory/:id()",
    meta: indextQ5FmsgUYKMeta || {},
    alias: indextQ5FmsgUYKMeta?.alias || [],
    redirect: indextQ5FmsgUYKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/orderhistory/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextQ5FmsgUYKMeta?.name ?? "mypage-orderhistory-id___ja",
    path: indextQ5FmsgUYKMeta?.path ?? "/ja/mypage/orderhistory/:id()",
    meta: indextQ5FmsgUYKMeta || {},
    alias: indextQ5FmsgUYKMeta?.alias || [],
    redirect: indextQ5FmsgUYKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/orderhistory/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextQ5FmsgUYKMeta?.name ?? "mypage-orderhistory-id___en",
    path: indextQ5FmsgUYKMeta?.path ?? "/en/mypage/orderhistory/:id()",
    meta: indextQ5FmsgUYKMeta || {},
    alias: indextQ5FmsgUYKMeta?.alias || [],
    redirect: indextQ5FmsgUYKMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/orderhistory/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNuCOgbn41pMeta?.name ?? "mypage-orderhistory___ja___default",
    path: indexNuCOgbn41pMeta?.path ?? "/mypage/orderhistory",
    meta: indexNuCOgbn41pMeta || {},
    alias: indexNuCOgbn41pMeta?.alias || [],
    redirect: indexNuCOgbn41pMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/orderhistory/index.vue").then(m => m.default || m)
  },
  {
    name: indexNuCOgbn41pMeta?.name ?? "mypage-orderhistory___ja",
    path: indexNuCOgbn41pMeta?.path ?? "/ja/mypage/orderhistory",
    meta: indexNuCOgbn41pMeta || {},
    alias: indexNuCOgbn41pMeta?.alias || [],
    redirect: indexNuCOgbn41pMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/orderhistory/index.vue").then(m => m.default || m)
  },
  {
    name: indexNuCOgbn41pMeta?.name ?? "mypage-orderhistory___en",
    path: indexNuCOgbn41pMeta?.path ?? "/en/mypage/orderhistory",
    meta: indexNuCOgbn41pMeta || {},
    alias: indexNuCOgbn41pMeta?.alias || [],
    redirect: indexNuCOgbn41pMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/orderhistory/index.vue").then(m => m.default || m)
  },
  {
    name: profilemyK7tujKNGMeta?.name ?? "mypage-profile___ja___default",
    path: profilemyK7tujKNGMeta?.path ?? "/mypage/profile",
    meta: profilemyK7tujKNGMeta || {},
    alias: profilemyK7tujKNGMeta?.alias || [],
    redirect: profilemyK7tujKNGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: profilemyK7tujKNGMeta?.name ?? "mypage-profile___ja",
    path: profilemyK7tujKNGMeta?.path ?? "/ja/mypage/profile",
    meta: profilemyK7tujKNGMeta || {},
    alias: profilemyK7tujKNGMeta?.alias || [],
    redirect: profilemyK7tujKNGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: profilemyK7tujKNGMeta?.name ?? "mypage-profile___en",
    path: profilemyK7tujKNGMeta?.path ?? "/en/mypage/profile",
    meta: profilemyK7tujKNGMeta || {},
    alias: profilemyK7tujKNGMeta?.alias || [],
    redirect: profilemyK7tujKNGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: indexahA77uMctsMeta?.name ?? "mypage-sales-list-yyyy-mm-dd___ja___default",
    path: indexahA77uMctsMeta?.path ?? "/mypage/sales/list/:yyyy()-:mm()-:dd()",
    meta: indexahA77uMctsMeta || {},
    alias: indexahA77uMctsMeta?.alias || [],
    redirect: indexahA77uMctsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]-[dd]/index.vue").then(m => m.default || m)
  },
  {
    name: indexahA77uMctsMeta?.name ?? "mypage-sales-list-yyyy-mm-dd___ja",
    path: indexahA77uMctsMeta?.path ?? "/ja/mypage/sales/list/:yyyy()-:mm()-:dd()",
    meta: indexahA77uMctsMeta || {},
    alias: indexahA77uMctsMeta?.alias || [],
    redirect: indexahA77uMctsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]-[dd]/index.vue").then(m => m.default || m)
  },
  {
    name: indexahA77uMctsMeta?.name ?? "mypage-sales-list-yyyy-mm-dd___en",
    path: indexahA77uMctsMeta?.path ?? "/en/mypage/sales/list/:yyyy()-:mm()-:dd()",
    meta: indexahA77uMctsMeta || {},
    alias: indexahA77uMctsMeta?.alias || [],
    redirect: indexahA77uMctsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]-[dd]/index.vue").then(m => m.default || m)
  },
  {
    name: index7EPyE86SeOMeta?.name ?? "mypage-sales-list-yyyy-mm___ja___default",
    path: index7EPyE86SeOMeta?.path ?? "/mypage/sales/list/:yyyy()-:mm()",
    meta: index7EPyE86SeOMeta || {},
    alias: index7EPyE86SeOMeta?.alias || [],
    redirect: index7EPyE86SeOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]/index.vue").then(m => m.default || m)
  },
  {
    name: index7EPyE86SeOMeta?.name ?? "mypage-sales-list-yyyy-mm___ja",
    path: index7EPyE86SeOMeta?.path ?? "/ja/mypage/sales/list/:yyyy()-:mm()",
    meta: index7EPyE86SeOMeta || {},
    alias: index7EPyE86SeOMeta?.alias || [],
    redirect: index7EPyE86SeOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]/index.vue").then(m => m.default || m)
  },
  {
    name: index7EPyE86SeOMeta?.name ?? "mypage-sales-list-yyyy-mm___en",
    path: index7EPyE86SeOMeta?.path ?? "/en/mypage/sales/list/:yyyy()-:mm()",
    meta: index7EPyE86SeOMeta || {},
    alias: index7EPyE86SeOMeta?.alias || [],
    redirect: index7EPyE86SeOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]-[mm]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQD2lKpyKLZMeta?.name ?? "mypage-sales-list-yyyy___ja___default",
    path: indexQD2lKpyKLZMeta?.path ?? "/mypage/sales/list/:yyyy()",
    meta: indexQD2lKpyKLZMeta || {},
    alias: indexQD2lKpyKLZMeta?.alias || [],
    redirect: indexQD2lKpyKLZMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQD2lKpyKLZMeta?.name ?? "mypage-sales-list-yyyy___ja",
    path: indexQD2lKpyKLZMeta?.path ?? "/ja/mypage/sales/list/:yyyy()",
    meta: indexQD2lKpyKLZMeta || {},
    alias: indexQD2lKpyKLZMeta?.alias || [],
    redirect: indexQD2lKpyKLZMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQD2lKpyKLZMeta?.name ?? "mypage-sales-list-yyyy___en",
    path: indexQD2lKpyKLZMeta?.path ?? "/en/mypage/sales/list/:yyyy()",
    meta: indexQD2lKpyKLZMeta || {},
    alias: indexQD2lKpyKLZMeta?.alias || [],
    redirect: indexQD2lKpyKLZMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/[yyyy]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKqkYXbtyrHMeta?.name ?? "mypage-sales-list___ja___default",
    path: indexKqkYXbtyrHMeta?.path ?? "/mypage/sales/list",
    meta: indexKqkYXbtyrHMeta || {},
    alias: indexKqkYXbtyrHMeta?.alias || [],
    redirect: indexKqkYXbtyrHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/index.vue").then(m => m.default || m)
  },
  {
    name: indexKqkYXbtyrHMeta?.name ?? "mypage-sales-list___ja",
    path: indexKqkYXbtyrHMeta?.path ?? "/ja/mypage/sales/list",
    meta: indexKqkYXbtyrHMeta || {},
    alias: indexKqkYXbtyrHMeta?.alias || [],
    redirect: indexKqkYXbtyrHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/index.vue").then(m => m.default || m)
  },
  {
    name: indexKqkYXbtyrHMeta?.name ?? "mypage-sales-list___en",
    path: indexKqkYXbtyrHMeta?.path ?? "/en/mypage/sales/list",
    meta: indexKqkYXbtyrHMeta || {},
    alias: indexKqkYXbtyrHMeta?.alias || [],
    redirect: indexKqkYXbtyrHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/list/index.vue").then(m => m.default || m)
  },
  {
    name: indexkzzWkpqx7fMeta?.name ?? "mypage-sales-transfer-setting___ja___default",
    path: indexkzzWkpqx7fMeta?.path ?? "/mypage/sales/transfer-setting",
    meta: indexkzzWkpqx7fMeta || {},
    alias: indexkzzWkpqx7fMeta?.alias || [],
    redirect: indexkzzWkpqx7fMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/transfer-setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexkzzWkpqx7fMeta?.name ?? "mypage-sales-transfer-setting___ja",
    path: indexkzzWkpqx7fMeta?.path ?? "/ja/mypage/sales/transfer-setting",
    meta: indexkzzWkpqx7fMeta || {},
    alias: indexkzzWkpqx7fMeta?.alias || [],
    redirect: indexkzzWkpqx7fMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/transfer-setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexkzzWkpqx7fMeta?.name ?? "mypage-sales-transfer-setting___en",
    path: indexkzzWkpqx7fMeta?.path ?? "/en/mypage/sales/transfer-setting",
    meta: indexkzzWkpqx7fMeta || {},
    alias: indexkzzWkpqx7fMeta?.alias || [],
    redirect: indexkzzWkpqx7fMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/transfer-setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZCaNeIydGMeta?.name ?? "mypage-sales-transfer-status___ja___default",
    path: indexBZCaNeIydGMeta?.path ?? "/mypage/sales/transfer-status",
    meta: indexBZCaNeIydGMeta || {},
    alias: indexBZCaNeIydGMeta?.alias || [],
    redirect: indexBZCaNeIydGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/transfer-status/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZCaNeIydGMeta?.name ?? "mypage-sales-transfer-status___ja",
    path: indexBZCaNeIydGMeta?.path ?? "/ja/mypage/sales/transfer-status",
    meta: indexBZCaNeIydGMeta || {},
    alias: indexBZCaNeIydGMeta?.alias || [],
    redirect: indexBZCaNeIydGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/transfer-status/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZCaNeIydGMeta?.name ?? "mypage-sales-transfer-status___en",
    path: indexBZCaNeIydGMeta?.path ?? "/en/mypage/sales/transfer-status",
    meta: indexBZCaNeIydGMeta || {},
    alias: indexBZCaNeIydGMeta?.alias || [],
    redirect: indexBZCaNeIydGMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/sales/transfer-status/index.vue").then(m => m.default || m)
  },
  {
    name: settinggCe6j2Eh3DMeta?.name ?? "mypage-setting___ja___default",
    path: settinggCe6j2Eh3DMeta?.path ?? "/mypage/setting",
    meta: settinggCe6j2Eh3DMeta || {},
    alias: settinggCe6j2Eh3DMeta?.alias || [],
    redirect: settinggCe6j2Eh3DMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/setting.vue").then(m => m.default || m)
  },
  {
    name: settinggCe6j2Eh3DMeta?.name ?? "mypage-setting___ja",
    path: settinggCe6j2Eh3DMeta?.path ?? "/ja/mypage/setting",
    meta: settinggCe6j2Eh3DMeta || {},
    alias: settinggCe6j2Eh3DMeta?.alias || [],
    redirect: settinggCe6j2Eh3DMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/setting.vue").then(m => m.default || m)
  },
  {
    name: settinggCe6j2Eh3DMeta?.name ?? "mypage-setting___en",
    path: settinggCe6j2Eh3DMeta?.path ?? "/en/mypage/setting",
    meta: settinggCe6j2Eh3DMeta || {},
    alias: settinggCe6j2Eh3DMeta?.alias || [],
    redirect: settinggCe6j2Eh3DMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/mypage/setting.vue").then(m => m.default || m)
  },
  {
    name: listE88o3kpwZ3Meta?.name ?? "myroom-list___ja___default",
    path: listE88o3kpwZ3Meta?.path ?? "/myroom/list",
    meta: listE88o3kpwZ3Meta || {},
    alias: listE88o3kpwZ3Meta?.alias || [],
    redirect: listE88o3kpwZ3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/myroom/list.vue").then(m => m.default || m)
  },
  {
    name: listE88o3kpwZ3Meta?.name ?? "myroom-list___ja",
    path: listE88o3kpwZ3Meta?.path ?? "/ja/myroom/list",
    meta: listE88o3kpwZ3Meta || {},
    alias: listE88o3kpwZ3Meta?.alias || [],
    redirect: listE88o3kpwZ3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/myroom/list.vue").then(m => m.default || m)
  },
  {
    name: listE88o3kpwZ3Meta?.name ?? "myroom-list___en",
    path: listE88o3kpwZ3Meta?.path ?? "/en/myroom/list",
    meta: listE88o3kpwZ3Meta || {},
    alias: listE88o3kpwZ3Meta?.alias || [],
    redirect: listE88o3kpwZ3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/myroom/list.vue").then(m => m.default || m)
  },
  {
    name: index4TNeElGSFPMeta?.name ?? "payment___ja___default",
    path: index4TNeElGSFPMeta?.path ?? "/payment",
    meta: index4TNeElGSFPMeta || {},
    alias: index4TNeElGSFPMeta?.alias || [],
    redirect: index4TNeElGSFPMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: index4TNeElGSFPMeta?.name ?? "payment___ja",
    path: index4TNeElGSFPMeta?.path ?? "/ja/payment",
    meta: index4TNeElGSFPMeta || {},
    alias: index4TNeElGSFPMeta?.alias || [],
    redirect: index4TNeElGSFPMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: index4TNeElGSFPMeta?.name ?? "payment___en",
    path: index4TNeElGSFPMeta?.path ?? "/en/payment",
    meta: index4TNeElGSFPMeta || {},
    alias: index4TNeElGSFPMeta?.alias || [],
    redirect: index4TNeElGSFPMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: index3iJheNbbruMeta?.name ?? "play-event-id___ja___default",
    path: index3iJheNbbruMeta?.path ?? "/play/event/:id()",
    meta: index3iJheNbbruMeta || {},
    alias: index3iJheNbbruMeta?.alias || [],
    redirect: index3iJheNbbruMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index3iJheNbbruMeta?.name ?? "play-event-id___ja",
    path: index3iJheNbbruMeta?.path ?? "/ja/play/event/:id()",
    meta: index3iJheNbbruMeta || {},
    alias: index3iJheNbbruMeta?.alias || [],
    redirect: index3iJheNbbruMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index3iJheNbbruMeta?.name ?? "play-event-id___en",
    path: index3iJheNbbruMeta?.path ?? "/en/play/event/:id()",
    meta: index3iJheNbbruMeta || {},
    alias: index3iJheNbbruMeta?.alias || [],
    redirect: index3iJheNbbruMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index70pg6OSH3tMeta?.name ?? "play-event___ja___default",
    path: index70pg6OSH3tMeta?.path ?? "/play/event",
    meta: index70pg6OSH3tMeta || {},
    alias: index70pg6OSH3tMeta?.alias || [],
    redirect: index70pg6OSH3tMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/index.vue").then(m => m.default || m)
  },
  {
    name: index70pg6OSH3tMeta?.name ?? "play-event___ja",
    path: index70pg6OSH3tMeta?.path ?? "/ja/play/event",
    meta: index70pg6OSH3tMeta || {},
    alias: index70pg6OSH3tMeta?.alias || [],
    redirect: index70pg6OSH3tMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/index.vue").then(m => m.default || m)
  },
  {
    name: index70pg6OSH3tMeta?.name ?? "play-event___en",
    path: index70pg6OSH3tMeta?.path ?? "/en/play/event",
    meta: index70pg6OSH3tMeta || {},
    alias: index70pg6OSH3tMeta?.alias || [],
    redirect: index70pg6OSH3tMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/index.vue").then(m => m.default || m)
  },
  {
    name: listBFs0GIvc9bMeta?.name ?? "play-event-list___ja___default",
    path: listBFs0GIvc9bMeta?.path ?? "/play/event/list",
    meta: listBFs0GIvc9bMeta || {},
    alias: listBFs0GIvc9bMeta?.alias || [],
    redirect: listBFs0GIvc9bMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/list.vue").then(m => m.default || m)
  },
  {
    name: listBFs0GIvc9bMeta?.name ?? "play-event-list___ja",
    path: listBFs0GIvc9bMeta?.path ?? "/ja/play/event/list",
    meta: listBFs0GIvc9bMeta || {},
    alias: listBFs0GIvc9bMeta?.alias || [],
    redirect: listBFs0GIvc9bMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/list.vue").then(m => m.default || m)
  },
  {
    name: listBFs0GIvc9bMeta?.name ?? "play-event-list___en",
    path: listBFs0GIvc9bMeta?.path ?? "/en/play/event/list",
    meta: listBFs0GIvc9bMeta || {},
    alias: listBFs0GIvc9bMeta?.alias || [],
    redirect: listBFs0GIvc9bMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/event/list.vue").then(m => m.default || m)
  },
  {
    name: indexBZlUg0h7bsMeta?.name ?? "play-world-id___ja___default",
    path: indexBZlUg0h7bsMeta?.path ?? "/play/world/:id()",
    meta: indexBZlUg0h7bsMeta || {},
    alias: indexBZlUg0h7bsMeta?.alias || [],
    redirect: indexBZlUg0h7bsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZlUg0h7bsMeta?.name ?? "play-world-id___ja",
    path: indexBZlUg0h7bsMeta?.path ?? "/ja/play/world/:id()",
    meta: indexBZlUg0h7bsMeta || {},
    alias: indexBZlUg0h7bsMeta?.alias || [],
    redirect: indexBZlUg0h7bsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZlUg0h7bsMeta?.name ?? "play-world-id___en",
    path: indexBZlUg0h7bsMeta?.path ?? "/en/play/world/:id()",
    meta: indexBZlUg0h7bsMeta || {},
    alias: indexBZlUg0h7bsMeta?.alias || [],
    redirect: indexBZlUg0h7bsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhFugl59NeuMeta?.name ?? "play-world___ja___default",
    path: indexhFugl59NeuMeta?.path ?? "/play/world",
    meta: indexhFugl59NeuMeta || {},
    alias: indexhFugl59NeuMeta?.alias || [],
    redirect: indexhFugl59NeuMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/index.vue").then(m => m.default || m)
  },
  {
    name: indexhFugl59NeuMeta?.name ?? "play-world___ja",
    path: indexhFugl59NeuMeta?.path ?? "/ja/play/world",
    meta: indexhFugl59NeuMeta || {},
    alias: indexhFugl59NeuMeta?.alias || [],
    redirect: indexhFugl59NeuMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/index.vue").then(m => m.default || m)
  },
  {
    name: indexhFugl59NeuMeta?.name ?? "play-world___en",
    path: indexhFugl59NeuMeta?.path ?? "/en/play/world",
    meta: indexhFugl59NeuMeta || {},
    alias: indexhFugl59NeuMeta?.alias || [],
    redirect: indexhFugl59NeuMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/index.vue").then(m => m.default || m)
  },
  {
    name: listN7Tg21b0EFMeta?.name ?? "play-world-list___ja___default",
    path: listN7Tg21b0EFMeta?.path ?? "/play/world/list",
    meta: listN7Tg21b0EFMeta || {},
    alias: listN7Tg21b0EFMeta?.alias || [],
    redirect: listN7Tg21b0EFMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/list.vue").then(m => m.default || m)
  },
  {
    name: listN7Tg21b0EFMeta?.name ?? "play-world-list___ja",
    path: listN7Tg21b0EFMeta?.path ?? "/ja/play/world/list",
    meta: listN7Tg21b0EFMeta || {},
    alias: listN7Tg21b0EFMeta?.alias || [],
    redirect: listN7Tg21b0EFMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/list.vue").then(m => m.default || m)
  },
  {
    name: listN7Tg21b0EFMeta?.name ?? "play-world-list___en",
    path: listN7Tg21b0EFMeta?.path ?? "/en/play/world/list",
    meta: listN7Tg21b0EFMeta || {},
    alias: listN7Tg21b0EFMeta?.alias || [],
    redirect: listN7Tg21b0EFMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/play/world/list.vue").then(m => m.default || m)
  },
  {
    name: achievementqhjIHN0EBEMeta?.name ?? "profile-id-achievement___ja___default",
    path: achievementqhjIHN0EBEMeta?.path ?? "/profile/:id()/achievement",
    meta: achievementqhjIHN0EBEMeta || {},
    alias: achievementqhjIHN0EBEMeta?.alias || [],
    redirect: achievementqhjIHN0EBEMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/achievement.vue").then(m => m.default || m)
  },
  {
    name: achievementqhjIHN0EBEMeta?.name ?? "profile-id-achievement___ja",
    path: achievementqhjIHN0EBEMeta?.path ?? "/ja/profile/:id()/achievement",
    meta: achievementqhjIHN0EBEMeta || {},
    alias: achievementqhjIHN0EBEMeta?.alias || [],
    redirect: achievementqhjIHN0EBEMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/achievement.vue").then(m => m.default || m)
  },
  {
    name: achievementqhjIHN0EBEMeta?.name ?? "profile-id-achievement___en",
    path: achievementqhjIHN0EBEMeta?.path ?? "/en/profile/:id()/achievement",
    meta: achievementqhjIHN0EBEMeta || {},
    alias: achievementqhjIHN0EBEMeta?.alias || [],
    redirect: achievementqhjIHN0EBEMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/achievement.vue").then(m => m.default || m)
  },
  {
    name: cloudstoreg7j6KqJxB0Meta?.name ?? "profile-id-cloudstore___ja___default",
    path: cloudstoreg7j6KqJxB0Meta?.path ?? "/profile/:id()/cloudstore",
    meta: cloudstoreg7j6KqJxB0Meta || {},
    alias: cloudstoreg7j6KqJxB0Meta?.alias || [],
    redirect: cloudstoreg7j6KqJxB0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/cloudstore.vue").then(m => m.default || m)
  },
  {
    name: cloudstoreg7j6KqJxB0Meta?.name ?? "profile-id-cloudstore___ja",
    path: cloudstoreg7j6KqJxB0Meta?.path ?? "/ja/profile/:id()/cloudstore",
    meta: cloudstoreg7j6KqJxB0Meta || {},
    alias: cloudstoreg7j6KqJxB0Meta?.alias || [],
    redirect: cloudstoreg7j6KqJxB0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/cloudstore.vue").then(m => m.default || m)
  },
  {
    name: cloudstoreg7j6KqJxB0Meta?.name ?? "profile-id-cloudstore___en",
    path: cloudstoreg7j6KqJxB0Meta?.path ?? "/en/profile/:id()/cloudstore",
    meta: cloudstoreg7j6KqJxB0Meta || {},
    alias: cloudstoreg7j6KqJxB0Meta?.alias || [],
    redirect: cloudstoreg7j6KqJxB0Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/cloudstore.vue").then(m => m.default || m)
  },
  {
    name: indexKyjUJeLOnOMeta?.name ?? "profile-id___ja___default",
    path: indexKyjUJeLOnOMeta?.path ?? "/profile/:id()",
    meta: indexKyjUJeLOnOMeta || {},
    alias: indexKyjUJeLOnOMeta?.alias || [],
    redirect: indexKyjUJeLOnOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKyjUJeLOnOMeta?.name ?? "profile-id___ja",
    path: indexKyjUJeLOnOMeta?.path ?? "/ja/profile/:id()",
    meta: indexKyjUJeLOnOMeta || {},
    alias: indexKyjUJeLOnOMeta?.alias || [],
    redirect: indexKyjUJeLOnOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKyjUJeLOnOMeta?.name ?? "profile-id___en",
    path: indexKyjUJeLOnOMeta?.path ?? "/en/profile/:id()",
    meta: indexKyjUJeLOnOMeta || {},
    alias: indexKyjUJeLOnOMeta?.alias || [],
    redirect: indexKyjUJeLOnOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: worldyU4nEuTG7VMeta?.name ?? "profile-id-world___ja___default",
    path: worldyU4nEuTG7VMeta?.path ?? "/profile/:id()/world",
    meta: worldyU4nEuTG7VMeta || {},
    alias: worldyU4nEuTG7VMeta?.alias || [],
    redirect: worldyU4nEuTG7VMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/world.vue").then(m => m.default || m)
  },
  {
    name: worldyU4nEuTG7VMeta?.name ?? "profile-id-world___ja",
    path: worldyU4nEuTG7VMeta?.path ?? "/ja/profile/:id()/world",
    meta: worldyU4nEuTG7VMeta || {},
    alias: worldyU4nEuTG7VMeta?.alias || [],
    redirect: worldyU4nEuTG7VMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/world.vue").then(m => m.default || m)
  },
  {
    name: worldyU4nEuTG7VMeta?.name ?? "profile-id-world___en",
    path: worldyU4nEuTG7VMeta?.path ?? "/en/profile/:id()/world",
    meta: worldyU4nEuTG7VMeta || {},
    alias: worldyU4nEuTG7VMeta?.alias || [],
    redirect: worldyU4nEuTG7VMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/profile/[id]/world.vue").then(m => m.default || m)
  },
  {
    name: indexK4kQxQ8rpTMeta?.name ?? "room-id___ja___default",
    path: indexK4kQxQ8rpTMeta?.path ?? "/room/:id()",
    meta: indexK4kQxQ8rpTMeta || {},
    alias: indexK4kQxQ8rpTMeta?.alias || [],
    redirect: indexK4kQxQ8rpTMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/room/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexK4kQxQ8rpTMeta?.name ?? "room-id___ja",
    path: indexK4kQxQ8rpTMeta?.path ?? "/ja/room/:id()",
    meta: indexK4kQxQ8rpTMeta || {},
    alias: indexK4kQxQ8rpTMeta?.alias || [],
    redirect: indexK4kQxQ8rpTMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/room/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexK4kQxQ8rpTMeta?.name ?? "room-id___en",
    path: indexK4kQxQ8rpTMeta?.path ?? "/en/room/:id()",
    meta: indexK4kQxQ8rpTMeta || {},
    alias: indexK4kQxQ8rpTMeta?.alias || [],
    redirect: indexK4kQxQ8rpTMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/room/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexynF36WTUfDMeta?.name ?? "talkroom___ja___default",
    path: indexynF36WTUfDMeta?.path ?? "/talkroom",
    meta: indexynF36WTUfDMeta || {},
    alias: indexynF36WTUfDMeta?.alias || [],
    redirect: indexynF36WTUfDMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/talkroom/index.vue").then(m => m.default || m)
  },
  {
    name: indexynF36WTUfDMeta?.name ?? "talkroom___ja",
    path: indexynF36WTUfDMeta?.path ?? "/ja/talkroom",
    meta: indexynF36WTUfDMeta || {},
    alias: indexynF36WTUfDMeta?.alias || [],
    redirect: indexynF36WTUfDMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/talkroom/index.vue").then(m => m.default || m)
  },
  {
    name: indexynF36WTUfDMeta?.name ?? "talkroom___en",
    path: indexynF36WTUfDMeta?.path ?? "/en/talkroom",
    meta: indexynF36WTUfDMeta || {},
    alias: indexynF36WTUfDMeta?.alias || [],
    redirect: indexynF36WTUfDMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/talkroom/index.vue").then(m => m.default || m)
  },
  {
    name: list99EMwI8BaOMeta?.name ?? "talkroom-list___ja___default",
    path: list99EMwI8BaOMeta?.path ?? "/talkroom/list",
    meta: list99EMwI8BaOMeta || {},
    alias: list99EMwI8BaOMeta?.alias || [],
    redirect: list99EMwI8BaOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/talkroom/list.vue").then(m => m.default || m)
  },
  {
    name: list99EMwI8BaOMeta?.name ?? "talkroom-list___ja",
    path: list99EMwI8BaOMeta?.path ?? "/ja/talkroom/list",
    meta: list99EMwI8BaOMeta || {},
    alias: list99EMwI8BaOMeta?.alias || [],
    redirect: list99EMwI8BaOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/talkroom/list.vue").then(m => m.default || m)
  },
  {
    name: list99EMwI8BaOMeta?.name ?? "talkroom-list___en",
    path: list99EMwI8BaOMeta?.path ?? "/en/talkroom/list",
    meta: list99EMwI8BaOMeta || {},
    alias: list99EMwI8BaOMeta?.alias || [],
    redirect: list99EMwI8BaOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/talkroom/list.vue").then(m => m.default || m)
  },
  {
    name: indexxXBK72epEZMeta?.name ?? "tutorial___ja___default",
    path: indexxXBK72epEZMeta?.path ?? "/tutorial",
    meta: indexxXBK72epEZMeta || {},
    alias: indexxXBK72epEZMeta?.alias || [],
    redirect: indexxXBK72epEZMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexxXBK72epEZMeta?.name ?? "tutorial___ja",
    path: indexxXBK72epEZMeta?.path ?? "/ja/tutorial",
    meta: indexxXBK72epEZMeta || {},
    alias: indexxXBK72epEZMeta?.alias || [],
    redirect: indexxXBK72epEZMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexxXBK72epEZMeta?.name ?? "tutorial___en",
    path: indexxXBK72epEZMeta?.path ?? "/en/tutorial",
    meta: indexxXBK72epEZMeta || {},
    alias: indexxXBK72epEZMeta?.alias || [],
    redirect: indexxXBK72epEZMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: index1Miyd6uTTnMeta?.name ?? "vketboothmaker-marche___ja___default",
    path: index1Miyd6uTTnMeta?.path ?? "/vketboothmaker-marche",
    meta: index1Miyd6uTTnMeta || {},
    alias: index1Miyd6uTTnMeta?.alias || [],
    redirect: index1Miyd6uTTnMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/vketboothmaker-marche/index.vue").then(m => m.default || m)
  },
  {
    name: index1Miyd6uTTnMeta?.name ?? "vketboothmaker-marche___ja",
    path: index1Miyd6uTTnMeta?.path ?? "/ja/vketboothmaker-marche",
    meta: index1Miyd6uTTnMeta || {},
    alias: index1Miyd6uTTnMeta?.alias || [],
    redirect: index1Miyd6uTTnMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/vketboothmaker-marche/index.vue").then(m => m.default || m)
  },
  {
    name: index1Miyd6uTTnMeta?.name ?? "vketboothmaker-marche___en",
    path: index1Miyd6uTTnMeta?.path ?? "/en/vketboothmaker-marche",
    meta: index1Miyd6uTTnMeta || {},
    alias: index1Miyd6uTTnMeta?.alias || [],
    redirect: index1Miyd6uTTnMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/vketboothmaker-marche/index.vue").then(m => m.default || m)
  }
]