// TODO: ヘッダーの言語を切り替えて setLocale した直後にエラー文も即変わってほしい（エラーを表示し直さないと変わらない

// NOTE: 販売アイテムの商品名や説明文は日本語・英語両方の入力が必要になる。その関係で「introductionJa」「introductionEn」といった区分けをしている

import { z } from 'zod'
import { localize, setLocale } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import ja from '@vee-validate/i18n/dist/locale/ja.json'
import AllRules from '@vee-validate/rules'
import { defineRule, configure } from 'vee-validate'

export default defineNuxtPlugin(({ $i18n }) => {
  const i18n = $i18n

  configure({
    // note: component側でもlocalizeで追加きる
    //       複数ページに使うもの、汎用的なものはこっちに定義
    generateMessage: localize({
      en: {
        ...en,
        names: {
          item: 'Input',
          name: 'Name',
          avatarName: 'Item Name',
          introduction: 'Introduction',
          description: 'Description',
          introductionJa: 'Introduction（Japanese）',
          introductionEn: 'Introduction',
          nsfw: 'R18',
          productNameJa: 'Product name（Japanese）',
          productNameEn: 'Product name',
          youtube: 'Youtube URL',
          termsJa: 'Terms of service（Japanese）',
          termsEn: 'Terms of service',
          price: 'Price',
          discount: 'Specify discount rate',
          vketId: 'Vket ID',
          jsonPath: 'JSON Path',
          polygonCount: 'Polygon count',
          itemName: 'Item Name',
          authorName: 'Author Name',
          webSite: 'Web/URL',
        },
      },
      ja: {
        ...ja,
        names: {
          item: 'この項目',
          name: '名前',
          avatarName: 'アイテム名',
          introduction: '紹介文',
          description: '説明文',
          introductionJa: '説明文（英語）',
          introductionEn: '説明文（英語）',
          nsfw: 'R18',
          productNameJa: '商品名',
          productNameEn: '商品名（英語）',
          youtube: 'Youtube URL',
          termsJa: '利用規約',
          termsEn: '利用規約（英語）',
          price: '価格',
          discount: '割引率',
          vketId: 'Vket ID',
          jsonPath: 'JSONパス',
          polygonCount: 'ポリゴン数',
          itemName: '作品名',
          authorName: '作者名',
          webSite: 'Web/URL',
        },
      },
    }),
  })

  Object.keys(AllRules).forEach((rule) => {
    const validationFunction = AllRules[rule]
    if (validationFunction) {
      // 全ルールを追加
      // @see https://github.com/logaretm/vee-validate/tree/main/packages/rules#available-rules
      defineRule(rule, validationFunction)
    }
  })

  defineRule('youtube', (value: string | null | undefined): boolean => {
    if (value) {
      return /^https:\/\/www.youtube.com\/.*/.test(value)
    }
    return false
  })

  defineRule('VketID', (value: string | null | undefined): boolean => {
    if (value) {
      return /^[a-z0-9_]+$/.test(value)
    }
    // NOTE: 必須チェックはrequiredで行う
    return true
  })

  // TODO: i18nがunknownなので、とりあえずzodで型を掘ります。型定義をちゃんとするなど、多分もっといい方法があるので、直す。どうしてもなさそうだったら、このコメントを消す
  ensureValueOf(
    z.object({
      locale: z.object({
        value: z.string(),
      }),
    }),
    i18n
  )

  // 全角カタカナ、全角英数、一部の全角特殊記号
  defineRule(
    'bank_account_name',
    (value: string | null | undefined): boolean => {
      if (value) {
        // eslint-disable-next-line no-irregular-whitespace
        return /^(?!.*ヲ)[ア-ンガ-ヴパ-ポ０-９Ａ-Ｚ（）．ー／　「」]+$/.test(
          value
        )
      }
      // NOTE: 必須チェックはrequiredで行う
      return true
    }
  )

  setLocale(i18n.locale.value)
})
